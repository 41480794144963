import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { Router } from "@angular/router";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
  MatTableDataSource,
} from "@angular/material";
import { DeviceService } from "src/app/core/services/device.service";
import { Config } from "src/app/core/data/config";
import { ConfirmDialog } from "src/app/pages/admin/device/device-warranty/device-warranty.component";
import { TranslateService } from "@ngx-translate/core";
import { isPlatformBrowser } from "@angular/common";
import { InteractionService } from "src/app/core/services/interaction.service";
import { DataExchangeService } from "src/app/core/services/data-exchange.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { DialogDeviceDetail } from "src/app/pages/admin/user/user-detail/user-detail.component";

export interface DoorSetting {
  id: number;
  name: string;
  status: string; //Door_Disabled_80_7d_3a_99_50_44_1
  close_setting_pulse: string; //"Close_Door_Settings_Pulses_80_7d_3a_9b_d7_54_1"
  open_setting_pulse: string; //"Open_Door_Settings_Pulses_80_7d_3a_9b_d7_54_1"
  close_pulse_time: string; //"Close_Pulse_Time_80_7d_3a_9b_d7_54_1"
  open_pulse_time: string; //"Open_Pulse_Time_80_7d_3a_9b_d7_54_1"
  partial_open_time: string; //"Partial_Open_Time_80_7d_3a_99_50_44_1"
  door_open_url: string; //"Partial_Open_Time_80_7d_3a_99_50_44_1"
  door_close_url: string; //"Partial_Open_Time_80_7d_3a_99_50_44_1"
}

@Component({
  selector: "app-my-device",
  templateUrl: "./my-device.component.html",
  styleUrls: ["./my-device.component.scss"],
})
export class MyDeviceComponent implements OnInit {
  allDevices = new Array();

  allMetas: any[] = new Array();
  doorSettings: DoorSetting[] = new Array();
  doorSettingsDataSource = new MatTableDataSource<DoorSetting>(
    this.doorSettings
  );

  doorStatus: boolean[] = [false, false, false];
  alldoorStatus = new Array();
  data = new Array();
  doorLocks = new Array();
  doorName = new Array();
  garage_count = new Array();
  doorData = new Array();
  doorDetail = new Array();
  checkbit = false;
  user_id;
  noDevice = false;
  imageBaseUrl;
  private helper = new JwtHelperService();

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _deviceservice: DeviceService,
    private _config: Config,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private _interactionService: InteractionService,
    private _dataExchangeService: DataExchangeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.imageBaseUrl = this._config.imageBaseUrl;
  }

  async ngOnInit() {
    if (!localStorage.getItem("refreshToken")) {
      localStorage.removeItem("token");
      this._router.navigate([""]);
    }
    this._interactionService.changeMenu(10);
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem("token")) {
        this._router.navigate([""]);
      } else {
        await this._authService.verifyUser("user", 10);
      }
      this.user_id = localStorage.getItem("user_id");
    }
    this._authService.getUserConnection().subscribe((res) => {
      localStorage.setItem(
        "email",
        //@ts-ignore
        res.user_pv_details[0].key_un
      );
      this._dataExchangeService.changeLoginStatus(
        //@ts-ignore
        "user" + res.user_pv_details[0].user_full_name
      );
    });

    /**
     * It will get all devices of the user and call an function for check door status.
     */

    if (this.helper.decodeToken(localStorage.getItem("token"))) {
      if (this.helper.decodeToken(localStorage.getItem("token")).data.user_id) {
        this._dataExchangeService.changeProgress(true);
        this._deviceservice
          .getUserDetail({
            id: this.helper.decodeToken(localStorage.getItem("token")).data
              .user_id,
          })
          .subscribe(
            (res) => {
              this._dataExchangeService.changeProgress(false);
              //@ts-ignore
              if (res.status == 1) {
                //@ts-ignore
                this.allDevices = res.devices;
                this.allMetas = res.meta;
                console.log("Response=>", res);
                //@ts-ignore
                this.data = new Array(res.devices.length);
                // //@ts-ignore
                for (let i = 0; i < this.allDevices.length; i++) {
                  this.allDevices[i].is_history_enable = true;
                  for (let j = 0; j < this.allDevices[i].garages.length; j++) {
                    this.doorData.push(this.allDevices[i].garages[j]);
                    this.allDevices[i].garages[j].door_open_url =
                      "https://connect.gotailwind.com/garage_app_ws/images/door_variant/0/garage_40.png";
                    this.allDevices[i].garages[j].door_close_url =
                      "https://connect.gotailwind.com/garage_app_ws/images/door_variant/0/garage_1.png";
                  }
                }
                for (let i = 0; i < this.allDevices.length; i++) {
                  this.doorDetail[i] = this.doorData.filter(
                    (door) => door.device_id === this.allDevices[i].id
                  );
                  this.allDevices[i].status = -1;
                }
                for (let i = 0; i < this.allMetas.length; i++) {
                  for (let j = 0; j < this.allDevices.length; j++) {
                    /**
                     * Partial Open Time
                     */
                    if (
                      this.allMetas[i].meta_key ===
                      "history" + this.allDevices[j].id
                    ) {
                      this.allDevices[j].is_history_enable =
                        this.allMetas[i].meta_value === "1";
                      break;
                    }

                    for (let k = 0; k < this.allDevices[j].garage_count; k++) {
                      let sufix = this.allDevices[j].garages[k].id;
                      /**
                       * Custom door Images
                       */
                      if (
                        this.allMetas[i].meta_key ===
                          "door_custom_images_selected" + sufix &&
                        this.allMetas[i].meta_value != "0"
                      ) {
                        this.allDevices[j].garages[k].door_open_url =
                          "https://connect.gotailwind.com/garage_app_ws/images/door_variant/" +
                          this.allMetas[i].meta_value +
                          "/garage_40.png";
                        this.allDevices[j].garages[k].door_close_url =
                          "https://connect.gotailwind.com/garage_app_ws/images/door_variant/" +
                          this.allMetas[i].meta_value +
                          "/garage_1.png";
                        break;
                      }
                    }
                  }
                }

                this.checkDoorStatus();
                this.checkbit = true;
              } else {
                this.noDevice = true;
                this._translate.get("NoDevice").subscribe((data) => {
                  //@ts-ignore
                  this._config.showMessage(res.message);
                });
              }
            },
            (err) => {
              this._dataExchangeService.changeProgress(false);
              this._translate.get("NoResponseFound").subscribe((data) => {
                this._config.showMessage(data);
              });
            }
          );
      } else {
        this._dataExchangeService.changeProgress(false);
        this._authService.loggedOut();
      }
    } else {
      this._dataExchangeService.changeProgress(false);
      this._authService.loggedOut();
    }
  }

  /** Open the pop-up when user clicks on locked out doors */

  openLockedOutDoor() {
    this._translate.get("LockedOutDoorMessage").subscribe((data) => {
      this.dialog.open(LockedOutDialog, {
        data: data,
        width: "550px",
      });
    });
  }

  /** Send the command to mqtt for open/close specific doors */

  async sendCommand(data, status, device_name, first, second) {
    let msg;
    if (status == 1) {
      this._translate.get("CloseDoorWarning").subscribe((data) => {
        msg = data;
      });
    } else {
      this._translate.get("OpenDoorWarning").subscribe((data) => {
        msg = data;
      });
    }
    msg = msg + device_name + " " + data.garage_name + "?";
    // const dr = this.dialog.open(ConfirmDialog, {
    //   data: msg,
    // });
    // dr.afterClosed().subscribe(async (result) => {
    //   if (result) {
        // console.log(status);
        // console.log(data);
        let commandTopic = data.device_id + "command";
        let commandPayload;
        let dialogSecond;
        let message;
        let command;
        // status =0 then open the door,status=1 then close the door
        if (status === 1) {
          command = -Math.abs(data.command_num);
          commandPayload = command.toString() + "*" + this.user_id.toString();
          dialogSecond = 60 * 1000;
          message = "Closing " + device_name + " " + data.garage_name;
        } else {
          command = data.command_num;
          commandPayload =
            data.command_num.toString() + "*" + this.user_id.toString();
          dialogSecond = 30 * 1000;
          message = "Opening " + device_name + " " + data.garage_name;
        }
        //console.log(command);
        const dialogRef = this.dialog.open(doorOpenDialog, {
          width: "300px",
          disableClose: true,
          data: message,
        });
        setTimeout(() => {
          dialogRef.close();
        }, dialogSecond);

        let changeStatus = await this._config._mqttService
          .observe(data.device_id + this._config.topics.door_status)
          .subscribe(async (cmd) => {
            //console.log(cmd.payload.toString());
            if (command.toString() == cmd.payload.toString()) {
              dialogRef.close();
              if (status == 1) {
                message = "Closed ";
                this.alldoorStatus[first][second] = false;
              } else {
                message = "Opened ";
                this.alldoorStatus[first][second] = true;
              }
              message += device_name + " " + data.garage_name;
              changeStatus.unsubscribe();

              //await this.checkDoorStatus();
              this._config.showMessage(message);
            } else {
              if (cmd.payload.toString() == "10") {
                dialogRef.close();
                changeStatus.unsubscribe();
                this._translate
                  .get("NotDoorActionPermission")
                  .subscribe((message) => {
                    this.dialog.open(LockedOutDialog, {
                      width: "500px",
                      data: message,
                    });
                  });
              }
            }
          });

        this._config._mqttService.unsafePublish(commandTopic, commandPayload, {
          qos: 1,
          retain: false,
        });
      }
  //   });
  // }

  /** Checks the door status and set door open/close/locked bits of all devices relatively */

  checkDoorStatus() {
    //console.log(this.allDevices);
    // console.log(this.allDevices);
    setTimeout(() => {
      for (let i = 0; i < this.allDevices.length; i++) {
        if (isPlatformBrowser(this.platformId)) {
          if (document.getElementById("spinner" + this.allDevices[i].id)) {
            if (
              document.getElementById("spinner" + this.allDevices[i].id).style
                .display != "none"
            ) {
              document.getElementById(
                "offline" + this.allDevices[i].id
              ).style.visibility = "visible";
              document.getElementById(
                "spinner" + this.allDevices[i].id
              ).style.display = "none";
              document.getElementById(
                "offline" + this.allDevices[i].id
              ).style.marginTop = "55px";
            }
          }
        }
      }
    }, 8000);
    // this._config._mqttService.onConnect.subscribe(s => {
    for (let i = 0; i < this.allDevices.length; i++) {
      if (this._config._mqttService != undefined) {
        this._config._mqttService.onConnect.subscribe(async (re) => {
          let status_init = await this._config._mqttService
            .observe(this.allDevices[i].id + this._config.topics.status)
            .subscribe((data) => {
              //console.log(data);
              this.allDevices[i].status = parseInt(data.payload.toString());
              if (this._config._development) {
                //console.log(status, this.allDevices[i].id);
              }
              this.doorName[i] = this.doorData.filter(
                (item) => item.device_id == this.allDevices[i].id
              );
              this.setDoors(this.allDevices[i].status, i);
              status_init.unsubscribe();
            });

          let details = await this._config._mqttService
            .observe(this.allDevices[i].id + this._config.topics.details)
            .subscribe((data) => {
              let recievedData = JSON.parse(data.payload.toString());
              if (this._config._development) {
                // console.log(recievedData);
                // console.log(this.allDevices[i]);
              }

              this.data.push(recievedData);
              this.garage_count[i] = recievedData.garage_count;
              let doorData = new Array();
              if (recievedData.garage_count == 1) {
                doorData.push(recievedData.door1_lock);
              } else {
                if (recievedData.garage_count == 2) {
                  doorData.push(recievedData.door1_lock);
                  doorData.push(recievedData.door2_lock);
                } else {
                  doorData.push(recievedData.door1_lock);
                  doorData.push(recievedData.door2_lock);
                  doorData.push(recievedData.door3_lock);
                }
              }
              this.doorLocks[i] = doorData;
              if (isPlatformBrowser(this.platformId)) {
                if (
                  this.allDevices[i].status >= 0 &&
                  this.allDevices[i].status <= 7
                ) {
                  if (
                    document.getElementById("spinner" + this.allDevices[i].id)
                  ) {
                    document.getElementById(
                      "spinner" + this.allDevices[i].id
                    ).style.display = "none";
                  }
                  if (
                    document.getElementById("actions" + this.allDevices[i].id)
                  ) {
                    document.getElementById(
                      "actions" + this.allDevices[i].id
                    ).style.display = "flex";
                  }
                  if (
                    document.getElementById(
                      "door-container" + this.allDevices[i].id
                    )
                  ) {
                    document.getElementById(
                      "door-container" + this.allDevices[i].id
                    ).style.marginTop = "-25px";
                  }
                  if (document.getElementById("card" + this.allDevices[i].id)) {
                    document.getElementById(
                      "card" + this.allDevices[i].id
                    ).style.borderLeft = "solid 8px #34a852";
                  }
                  if (
                    document.getElementById("offline" + this.allDevices[i].id)
                  ) {
                    document.getElementById(
                      "offline" + this.allDevices[i].id
                    ).style.visibility = "hidden";
                  }

                  document.getElementById(
                    "offline" + this.allDevices[i].id
                  ).style.marginTop = "0px";
                }
              }

              details.unsubscribe();
            });

          await this._config._mqttService.unsafePublish(
            this.allDevices[i].id + this._config.publish.command,
            "9",
            { qos: 1, retain: false }
          );
          await this._config._mqttService.unsafePublish(
            this.allDevices[i].id + this._config.publish.command,
            "-3",
            { qos: 1, retain: false }
          );
        });
      }

      this._config._mqttService
        .observe(this.allDevices[i].id + this._config.topics.status)
        .subscribe((data) => {
          //console.log(data);
          this.allDevices[i].status = parseInt(data.payload.toString());
          if (this._config._development) {
            //console.log(status, this.allDevices[i].id);
          }
          this.doorName[i] = this.doorData.filter(
            (item) => item.device_id == this.allDevices[i].id
          );
          this.setDoors(this.allDevices[i].status, i);
        });

      this._config._mqttService
        .observe(this.allDevices[i].id + this._config.topics.details)
        .subscribe((data) => {
          let recievedData = JSON.parse(data.payload.toString());
          if (this._config._development) {
            // console.log(recievedData);
            // console.log(this.allDevices[i]);
          }

          this.data.push(recievedData);
          this.garage_count[i] = recievedData.garage_count;
          let doorData = new Array();
          if (recievedData.garage_count == 1) {
            doorData.push(recievedData.door1_lock);
          } else {
            if (recievedData.garage_count == 2) {
              doorData.push(recievedData.door1_lock);
              doorData.push(recievedData.door2_lock);
            } else {
              doorData.push(recievedData.door1_lock);
              doorData.push(recievedData.door2_lock);
              doorData.push(recievedData.door3_lock);
            }
          }
          this.doorLocks[i] = doorData;
          if (isPlatformBrowser(this.platformId)) {
            if (
              this.allDevices[i].status >= 0 &&
              this.allDevices[i].status <= 7
            ) {
              if (document.getElementById("spinner" + this.allDevices[i].id)) {
                document.getElementById(
                  "spinner" + this.allDevices[i].id
                ).style.display = "none";
              }
              if (document.getElementById("actions" + this.allDevices[i].id)) {
                document.getElementById(
                  "actions" + this.allDevices[i].id
                ).style.display = "flex";
              }
              if (
                document.getElementById(
                  "door-container" + this.allDevices[i].id
                )
              ) {
                document.getElementById(
                  "door-container" + this.allDevices[i].id
                ).style.marginTop = "-25px";
              }
              if (document.getElementById("card" + this.allDevices[i].id)) {
                document.getElementById(
                  "card" + this.allDevices[i].id
                ).style.borderLeft = "solid 8px #34a852";
              }
              if (document.getElementById("offline" + this.allDevices[i].id)) {
                document.getElementById(
                  "offline" + this.allDevices[i].id
                ).style.visibility = "hidden";
              }
              if (document.getElementById("offline" + this.allDevices[i].id)) {
                document.getElementById(
                  "offline" + this.allDevices[i].id
                ).style.marginTop = "0px";
              }
            }
          }
        });
      this._config._mqttService.unsafePublish(
        this.allDevices[i].id + this._config.publish.command,
        "9",
        { qos: 1, retain: false }
      );
      this._config._mqttService.unsafePublish(
        this.allDevices[i].id + this._config.publish.command,
        "-3",
        { qos: 1, retain: false }
      );
    }
    // });
  }

  /** It will display the message of this functionaliy is in under developing */

  developingTool() {
    this._translate.get("UnderDevelopment").subscribe((data) => {
      this._config.showMessage(data);
    });
  }

  /** It will perform the actions when user clicks on the action buttons */

  performAction(type, device) {
    let data = {
      action_type: type,
      device: device,
      device_time_zone: device.device_time_zone,
    };
    this.dialog.open(ActionDialog, {
      data: data,
      height: "600px",
      width: "500px",
    });
  }

  /** It will update the firmware of the given device */

  updateFirmware(device) {
    if (device.version.length > 0) {
      let dialog;
      this._translate.get("UpdateFirmwareMessage").subscribe((data) => {
        dialog = this.dialog.open(ConfirmDialog, {
          data: data + device.device_name,
        });
      });

      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this._config._mqttService.unsafePublish(
            device.id + this._config.publish.command,
            "8",
            { qos: 1, retain: false }
          );
          this._translate.get("FirmwareUpdatedMessage").subscribe((data) => {
            this._config.showMessage(data);
          });
        }
      });
    } else {
      this._translate.get("NoVersionUpdate").subscribe((message) => {
        this._config.showMessage(message);
      });
    }
  }

  /** It will open dialog for displaying all settings of perticaular device */

  displaySettings(device) {
    for (let i = 0; i < device.beacon_config.length; i++) {
      if (device.beacon_config[i].uuid.length == 17) {
        device.beacon_config[i].type = "VB";
      } else {
        device.beacon_config[i].type = "TS";
      }
      if (
        device.garages.find(
          (item) => item.id == device.beacon_config[i].garage_id
        )
      ) {
        device.beacon_config[i].doorname = device.garages.find(
          (item) => item.id == device.beacon_config[i].garage_id
        ).garage_name;
      } else {
        device.garages.splice(i, 1);
      }
    }

    this.doorSettings = [];
    for (let i = 0; i < device.garage_count; i++) {
      let inc = 1;
      if (i == 2) inc = 2;
      this.doorSettings[i] = {
        id: i + inc,
        name: "Garage " + (i + 1),
        status: "-",
        close_setting_pulse: "-",
        open_setting_pulse: "-",
        close_pulse_time: "-",
        open_pulse_time: "-",
        partial_open_time: "-",
        door_open_url:
          "https://connect.gotailwind.com/garage_app_ws/images/door_variant/0/garage_40.png",
        door_close_url:
          "https://connect.gotailwind.com/garage_app_ws/images/door_variant/0/garage_1.png",
      };
    }

    console.log("Door setting before assignment", this.doorSettings);
    var nightModeDelay = 5
    for (let i = 0; i < this.allMetas.length; i++) {
      if (this.allMetas[i].meta_key === `Night_Mode_Door_Close_Time${device.id}`) {
        nightModeDelay = this.allMetas[i].meta_value / 60
      }

      for (let j = 0; j < device.garage_count; j++) {
        let sufix = device.id + this.doorSettings[j].id;
        if (this.allMetas[i].meta_key === "Door_Disabled" + sufix) {
          var d = new Date();
          var n = d.getTime();
          let time = this.allMetas[i].meta_value * 1;
          this.doorSettings[j].status = time < n ? "-" : "Disable till\n";

          if (time > n) {
            var date = new Date(time);
            this.doorSettings[j].status += date.toLocaleString("en-US", {
              timeZone: device.device_time_zone,
            });
          }
          break;
        }

        /**
         * close door setting pulse
         */
        if (
          this.allMetas[i].meta_key ===
          "Close_Door_Settings_Pulses" + sufix
        ) {
          this.doorSettings[j].close_setting_pulse = this.allMetas[
            i
          ].meta_value;
          break;
        }

        /**
         * Open door setting pulse
         */
        if (this.allMetas[i].meta_key === "Open_Door_Settings_Pulses" + sufix) {
          this.doorSettings[j].open_setting_pulse = this.allMetas[i].meta_value;
          break;
        }

        /**
         * Open pulse time
         */
        if (this.allMetas[i].meta_key === "Open_Pulse_Time" + sufix) {
          this.doorSettings[j].open_pulse_time = this.allMetas[i].meta_value;
          break;
        }

        /**
         * Close pulse time
         */
        if (this.allMetas[i].meta_key === "Close_Pulse_Time" + sufix) {
          this.doorSettings[j].close_pulse_time = this.allMetas[i].meta_value;
          break;
        }
        /**
         * Partial Open Time
         */
        if (this.allMetas[i].meta_key === "Partial_Open_Time" + sufix) {
          this.doorSettings[j].partial_open_time = this.allMetas[i].meta_value;
          break;
        }

        /**
         * Custom door Images
         */
        if (
          this.allMetas[i].meta_key === "door_custom_images_selected" + sufix &&
          this.allMetas[i].meta_value != "0"
        ) {
          this.doorSettings[j].door_open_url =
            "https://connect.gotailwind.com/garage_app_ws/images/door_variant/" +
            this.allMetas[i].meta_value +
            "/garage_40.png";
          this.doorSettings[j].door_close_url =
            "https://connect.gotailwind.com/garage_app_ws/images/door_variant/" +
            this.allMetas[i].meta_value +
            "/garage_1.png";
          break;
        }
      }
    }
    console.log("Door settings=>", this.doorSettings);
    this.dialog.open(DialogDeviceDetail, {
      height: "700px",
      width: "700px",
      data: {
        device_id: device.id,
        tz: device.device_time_zone,
        user_id: device.user_id,
        notifications: device.notification_setting,
        sensor: device.beacon_config,
        share_device: device.is_share_device,
        door_setting: this.doorSettings,
        showGarageHistory: false,
        nightModeDelay: nightModeDelay,
        integrationColumns: [
          "number",
          "user_full_name",
          //"meta_value"
        ]
      },
    });
  }

  /** It will set door status(open/close) */

  async setDoors(status, index) {
    switch (status) {
      case 1:
        this.doorStatus[0] = true;
        break;
      case 2:
        this.doorStatus[1] = true;
        break;
      case 3:
        this.doorStatus[0] = true;
        this.doorStatus[1] = true;
        break;
      case 4:
        this.doorStatus[2] = true;
        break;
      case 5:
        this.doorStatus[0] = true;
        this.doorStatus[2] = true;
        break;
      case 6:
        this.doorStatus[1] = true;
        this.doorStatus[2] = true;
        break;
      case 7:
        this.doorStatus[0] = true;
        this.doorStatus[1] = true;
        this.doorStatus[2] = true;
        break;
    }
    if (this.garage_count[index] == 1) {
      this.doorStatus[1] = false;
      this.doorStatus[2] = false;
    }

    if (this.garage_count[index] == 2) this.doorStatus[2] = false;
    this.alldoorStatus[index] = this.doorStatus;
    this.doorStatus = [false, false, false];
  }

  /** It will refresh the status of specified device. */

  refreshDevice(device_id, i) {
    let status;
    if (isPlatformBrowser(this.platformId)) {
      //document.getElementById("actions" + device_id).style.display = "none";
      document.getElementById(device_id + "_tb").style.display = "none";
      if (document.getElementById("offline" + device_id)) {
        document.getElementById("offline" + device_id).style.visibility =
          "hidden";
      }
      document.getElementById("spinner" + device_id).style.display = "block";
      document.getElementById("spinner" + device_id).style.textAlign = "center";
      document.getElementById("spinner" + device_id).style.textAlign =
        "-webkit-center";
    }
    this._config._mqttService
      .observe(device_id + this._config.topics.status)
      .subscribe((data) => {
        status = parseInt(data.payload.toString());
        if (this._config._development) {
          //console.log(status, this.allDevices[i].id);
        }
        this.doorName[i] = this.doorData.filter(
          (item) => item.device_id == device_id
        );
        this.setDoors(status, i);
      });

    this._config._mqttService
      .observe(device_id + this._config.topics.details)
      .subscribe((data) => {
        let recievedData = JSON.parse(data.payload.toString());
        if (this._config._development) {
          // console.log(recievedData);
          // console.log(this.allDevices[i]);
        }

        this.data.push(recievedData);
        this.garage_count[i] = recievedData.garage_count;
        let doorData = new Array();
        if (recievedData.garage_count == 1) {
          doorData.push(recievedData.door1_lock);
        } else {
          if (recievedData.garage_count == 2) {
            doorData.push(recievedData.door1_lock);
            doorData.push(recievedData.door2_lock);
          } else {
            doorData.push(recievedData.door1_lock);
            doorData.push(recievedData.door2_lock);
            doorData.push(recievedData.door3_lock);
          }
        }
        this.doorLocks[i] = doorData;
        if (isPlatformBrowser(this.platformId)) {
          if (status >= 0 && status <= 7) {
            if (document.getElementById("spinner" + device_id)) {
              document.getElementById("spinner" + device_id).style.display =
                "none";
            }

            document.getElementById("actions" + device_id).style.display =
              "flex";
            document.getElementById(
              "door-container" + device_id
            ).style.marginTop = "-25px";
            document.getElementById("card" + device_id).style.borderLeft =
              "solid 8px #34a852";
            document.getElementById(device_id + "_tb").style.display = "table";
            document.getElementById("offline" + device_id).style.visibility =
              "hidden";
            document.getElementById("offline" + device_id).style.marginTop =
              "0px";
          } else {
            if (document.getElementById("offline" + this.allDevices[i].id)) {
              document.getElementById(
                "offline" + this.allDevices[i].id
              ).style.visibility = "visible";
            }
          }
        }
      });
    this._config._mqttService.unsafePublish(
      device_id + this._config.publish.command,
      "9",
      { qos: 1, retain: false }
    );
    this._config._mqttService.unsafePublish(
      device_id + this._config.publish.command,
      "-3",
      { qos: 1, retain: false }
    );
    setTimeout(() => {
      if (
        document.getElementById("spinner" + device_id).style.display != "none"
      ) {
        document.getElementById("spinner" + device_id).style.display = "none";
        if (document.getElementById("offline" + this.allDevices[i].id)) {
          document.getElementById(
            "offline" + this.allDevices[i].id
          ).style.visibility = "visible";
        }
      }
    }, 5000);
  }
}
@Component({
  selector: "doorOpenDialog",
  template: `
    <div>
      <mat-spinner
        [diameter]="70"
        style="margin-left:auto;margin-right:auto;margin-bottom:30px;"
      ></mat-spinner
      ><br />
      <div style="text-align:center">{{ data }}</div>
    </div>
  `,
})
export class doorOpenDialog {
  constructor(
    public dialogRef: MatDialogRef<doorOpenDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
}

@Component({
  selector: "lockedOutDialog",
  template: `
    <div class="icon"><mat-icon class="info">info</mat-icon></div>
    <div class="main">{{ data }}</div>
    <br /><br />
    <mat-dialog-actions align="end">
      <button
        mat-raised-button
        color="primary"
        (click)="closeDialog(true)"
        cdkFocusInitial
      >
        OK
      </button>
    </mat-dialog-actions>
  `,
  styleUrls: ["../../../admin/device/device-warranty/ok-cancel.scss"],
})
export class LockedOutDialog {
  constructor(
    public dialogRef: MatDialogRef<doorOpenDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  closeDialog(result) {
    this.dialogRef.close(result);
  }
}

@Component({
  selector: "action-dialog",
  templateUrl: "./action-dialog.component.html",
  styleUrls: ["./action-dialog.component.scss"],
})
export class ActionDialog implements OnInit {
  public history = new Array();
  public nightMode = false;
  header;

  /**
   * It will perform taks based on action_type
   */
  constructor(
    public dialogRef: MatDialogRef<ActionDialog>,
    private _deviceService: DeviceService,
    private _dataExchangeService: DataExchangeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    switch (data.action_type) {
      /**
       * It will get the history data of the device_id getting from the data
       */

      case 1:
        this._deviceService.getHistoryOfDevices(data.device.id).subscribe(
          (res) => {
            document.getElementById("spinner").style.display = "none";
            //@ts-ignore
            if (res.status == 1) {
              //@ts-ignore
              this.history = res.DoorHistory;
              for (let i = 0; i < this.history.length; i++) {
                let ctime = new Date(this.history[i].datetime);
                //@ts-ignore
                ctime = new Date(ctime.toUTCString()).toLocaleString("en-US", {
                  timeZone: data.device_time_zone,
                  // timeZone: "America/Pacific"
                });
                //@ts-ignore
                ctime = new Date(ctime + " GMT").toLocaleString("en-US", {
                  timeZone: data.device_time_zone,
                  //timeZone: "America/Pacific"
                });
                this.history[i].datetime = ctime;
              }
              let filterdata = new Array();
              for (let i = 0; i < this.history.length; i++) {
                if (i == 0) {
                  filterdata.push({
                    datetime: this.history[i].datetime,
                    data: this.history.filter(
                      (item) =>
                        new Date(item.datetime).getDate() ==
                        new Date(this.history[i].datetime).getDate()
                    ),
                  });
                } else {
                  if (
                    new Date(this.history[i].datetime).getDate() !=
                    new Date(this.history[i - 1].datetime).getDate()
                  ) {
                    filterdata.push({
                      datetime: this.history[i].datetime,
                      data: this.history.filter(
                        (item) =>
                          new Date(item.datetime).getDate() ==
                          new Date(this.history[i].datetime).getDate()
                      ),
                    });
                  }
                }
              }
              delete this.history;
              this.history = new Array();
              this.history = filterdata;

              setTimeout(() => {
                document.getElementById("title").focus();
              }, 200);
            } else {
            }
          },
          (err) => {
            this._dataExchangeService.changeProgress(false);
          }
        );
        break;

      case 2:
        break;
    }
  }

  ngOnInit() {
    // window.onscroll = function() {
    //   let header = document.getElementById("header0");
    //   let sticky = header.offsetTop;
    //   if (window.pageYOffset > sticky) {
    //     document.getElementById("back").classList.add("fixed");
    //   } else {
    //     document.getElementById("back").classList.remove("fixed");
    //   }
    // };
  }

  toggle(event) {
    // console.log(event.checked);
    // console.log(this.nightMode);
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
